import { buildApiDomainUrl } from '../../../utils/domain'

export const CUSTOMER_BASE_URL = buildApiDomainUrl('https://customer.{domain}/v3.0')
export const PROPERTY_BASE_URL = buildApiDomainUrl('https://property.{domain}/v3.0')
export const FLATGUIDE_BASE_URL = buildApiDomainUrl('https://flatguide.{domain}/v3.0')
export const MISSION_BASE_URL = buildApiDomainUrl('https://mission.{domain}/v3.0')
export const BOOKING_BASE_URL = buildApiDomainUrl('https://booking.{domain}/v3.0')
export const BILLING_BASE_URL = buildApiDomainUrl('https://billing.{domain}/v3.0')
export const LOCATION_BASE_URL = buildApiDomainUrl('https://location.{domain}/v3.0')
export const FLATGUIDE_BILLING_BASE_URL = buildApiDomainUrl(
  'https://flatguidebilling.{domain}/v3.0'
)
