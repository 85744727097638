import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  gridNoBooking: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 16,
  },
  image: {
    width: 432,
    height: 252,
  },
  noTimeSlot: {
    textAlign: 'center',
  },
  contactUs: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },

  contactUsText: {
    paddingLeft: 4,
  },
  phoneNumber: {
    whiteSpace: 'nowrap',
  },
}))
