import {
  FlatsyMissionType,
  FlatsyProMissionInspection,
} from '@flatsy/api/client/3.0.0/services/Mission/data-contracts'
import { FlatsyPublicBookable } from '@flatsy/api/client/Booking/data-contracts'
import { Body1 } from '@flatsy/core/MaterialUi/Typo'
import FlatsyTheme from '@flatsy/core/MuiTheme/flatsyTheme'
import TimeBooking from '@flatsy/core/TimeBooking/Weeks/index'
import { DateTime } from '@flatsy/utils/dates'
import { Grid } from '@material-ui/core'
import { useContext, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ApplicationContext } from 'src/context/Application'
import ContactSupport from '@flatsy/core/Icon/ContactSupport'
import { useStyles } from './Style'
import { BookableProps } from './Type'

const LIMIT_SLOTS_FOR_SUPPORT_CONTACT = 4

export const Bookable: React.FC<BookableProps> = ({ isLoading }) => {
  const { i18n } = useTranslation()
  const classes = useStyles()
  const {
    missionQuery,
    booking: { bookable, selected, setSelected },
    startCalendar,
    setStartCalendar,
    isInsideDate,
  } = useContext(ApplicationContext)
  const missionInspection = missionQuery?.data as FlatsyProMissionInspection

  const renderSlot = () => {
    if (bookable && bookable.data) {
      return bookable.data.map<FlatsyPublicBookable>((value) => {
        const startAt = DateTime.fromISO(value.startsAt)
        return {
          date: startAt.toISODate(),
          start_time: startAt.toUTC().toISOTime(),
          score: value.score,
        }
      })
    }
  }

  const handleSlotOnClick = (startTime: string) => {
    setSelected(startTime)
  }

  const handleWeekChange = (startDate: string) => {
    setStartCalendar(startDate)
  }

  const startOfWeek = useMemo(() => {
    if (startCalendar) return DateTime.fromISO(startCalendar).toJSDate()
    if (
      missionQuery?.data?.type === FlatsyMissionType.INSPECTION &&
      missionInspection?.inspection?.preferredDates?.startsAt
    ) {
      return DateTime.fromISO(missionInspection.inspection?.preferredDates?.startsAt)
        .startOf('week')
        .toJSDate()
    }
  }, [missionInspection && missionInspection.inspection?.preferredDates?.startsAt, startCalendar])

  const dataLength = bookable?.data?.length

  return (
    <>
      <TimeBooking
        slots={renderSlot()}
        handleClickOnSlot={handleSlotOnClick}
        handleWeekChange={handleWeekChange}
        isLoading={isLoading}
        selectedDay={selected}
        startDate={startOfWeek}
        dataBooking={dataLength}
        isInsideDate={isInsideDate}
      />
      {(!isInsideDate || (dataLength ?? 0) <= LIMIT_SLOTS_FOR_SUPPORT_CONTACT) && (
        <Grid container alignItems="stretch">
          {!dataLength && (
            <>
              <Grid item xs />
              <Grid xs={6} className={classes.gridNoBooking}>
                <img src={FlatsyTheme.image.noBooking} alt="no booking" className={classes.image} />
              </Grid>
              <Grid item xs />
              <Grid item xs={12} className={classes.noTimeSlot}>
                <Body1>{i18n.t('no_time_slot_available')}</Body1>
              </Grid>
            </>
          )}
          <Grid item xs={12} className={classes.contactUs}>
            <ContactSupport />
            <Body1 className={classes.contactUsText}>
              <Trans
                i18nKey={!dataLength ? 'contact_us_at' : 'contact_us_at_if_you_need_help'}
                components={[<span className={classes.phoneNumber} />]}
              />
            </Body1>
          </Grid>
        </Grid>
      )}
    </>
  )
}
