/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  FlatsyAdminAssignment,
  FlatsyAdminThirdPartyTemplate,
  FlatsyMissionListItem,
  FlatsyMissionType,
  FlatsyPatchAdminAssignment,
  FlatsyPatchAdminMissionState,
  FlatsyPatchBookingStartsAt,
  FlatsyPatchMissionBookingsThreshold,
  FlatsyPostAssignment,
  FlatsyPostBooking,
  FlatsyProBookable,
  FlatsyProBooking,
  FlatsyProMissionCommercialViewing,
  FlatsyProMissionInspection,
  FlatsyPublicBooking,
  FlatsySingleDayTimeSlot,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Admin<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Retrieve a list of bookables (available slots) for a mission
   *
   * @tags Bookable
   * @name BookableList
   * @summary (Admin) Retrieve a list of bookables for a mission
   * @request GET:/admin/bookable/
   * @secure
   */
  bookableList = (
    query: {
      /** @format uuid */
      mission_id: string
      /** @format uuid */
      agent_id?: string
      /** @format date */
      starts_at: string
      /** @format date */
      ends_at: string
      /** @format uuid */
      booking_id_to_exclude?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      {
        /**
         * The total of items found
         * @example 800
         */
        count?: number
        items?: FlatsyProBookable[]
      },
      any
    >({
      path: `/admin/bookable/`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    })
  /**
   * @description Retrieve number of available slots for a mission
   *
   * @tags Bookable
   * @name BookableNumberAvailableSlotsList
   * @summary (Admin) Retrieve number of available slots for a mission
   * @request GET:/admin/bookable/number_available_slots
   * @secure
   */
  bookableNumberAvailableSlotsList = (
    query: {
      /** @format uuid */
      mission_id: string
      duration_in_minutes: number
      /** @format uuid */
      agent_id: string
      /** @format date */
      starts_at: string
      /** @format date */
      ends_at: string
      /** @format uuid */
      booking_id_to_exclude?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<Record<string, Record<string, FlatsySingleDayTimeSlot>>, any>({
      path: `/admin/bookable/number_available_slots`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    })
  /**
   * @description (Admin) Get a list of <<glossary:Booking>>s
   *
   * @tags Booking
   * @name BookingList
   * @summary (Admin) Get a list of Bookings
   * @request GET:/admin/booking
   * @secure
   */
  bookingList = (
    query?: {
      /**
       * To filter bookings based on ID of a mission
       * @format uuid
       * @pattern ^[\w\-. ]+$
       */
      mission_id?: string
      /**
       * To filter bookings based on ID of an agent
       * @format uuid
       * @pattern ^[\w\-. ]+$
       */
      agent_id?: string
      states?: string
      /**
       * To filter bookings to only show those which are scheduled after this date
       * @format date
       */
      starts_at?: string
      /**
       * To filter bookings to only show those which are scheduled before this date
       * @format date
       */
      ends_at?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      {
        items?: FlatsyProBooking[]
      },
      any
    >({
      path: `/admin/booking`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    })
  /**
   * @description Request Booking creation
   *
   * @tags Booking
   * @name BookingCreate
   * @summary (Admin) Create Booking
   * @request POST:/admin/booking
   * @secure
   */
  bookingCreate = (data: FlatsyPostBooking, params: RequestParams = {}) =>
    this.request<FlatsyPublicBooking, any>({
      path: `/admin/booking`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Retrieve a <<glossary:Booking>> by ID
   *
   * @tags Booking
   * @name BookingDetail
   * @summary (Admin) Get a Booking by ID
   * @request GET:/admin/booking/{id}
   * @secure
   */
  bookingDetail = (id: string, params: RequestParams = {}) =>
    this.request<
      FlatsyPublicBooking,
      {
        errors?: string[]
      }
    >({
      path: `/admin/booking/${id}`,
      method: 'GET',
      secure: true,
      ...params,
    })
  /**
   * @description Update <<glossary:Booking>> date and time to reschedule it.
   *
   * @tags Booking
   * @name BookingStartsAtPartialUpdate
   * @summary (Admin) Update a Booking date and time
   * @request PATCH:/admin/booking/{id}/starts_at
   * @secure
   */
  bookingStartsAtPartialUpdate = (
    id: string,
    data: FlatsyPatchBookingStartsAt,
    params: RequestParams = {}
  ) =>
    this.request<
      FlatsyPublicBooking,
      {
        errors?: (
          | 'BOOKING_ALREADY_EXISTS_FOR_STARTS_AT'
          | 'BOOKING_DOES_NOT_EXIST'
          | 'INVALID_BOOKING_STATE'
          | 'INVALID_MISSION_STATE'
          | 'MISSION_DOES_NOT_EXIST'
          | 'MISSION_IS_NOT_STAFFED_FOR_THIS_DATE'
          | 'STARTS_AT_IS_IN_THE_PAST'
          | 'STARTS_AT_REQUIRES_DATETIME'
        )[]
      } | void
    >({
      path: `/admin/booking/${id}/starts_at`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description (Admin) Get a list of <<glossary:Mission>>s with query search and filtering
   *
   * @tags Mission
   * @name MissionList
   * @summary (Admin) Get a list of Missions
   * @request GET:/admin/mission
   * @secure
   */
  missionList = (
    query?: {
      /**
       * Accepts a comma-separated list of search terms to search for in:
       *   - mission.id
       *   - customer.id
       *   - customer.name
       *   - flatguide.id
       *   - flatguide.firstName
       *   - flatguide.lastName
       *   - flatguide.email
       *   - flatguide.phoneNumber
       *   - mission.tenants[0].firstName
       *   - mission.tenants[0].lastName
       *   - mission.tenants[0].email
       *   - mission.tenants[0].phoneNumber
       *   - property.id
       *   - property.publication.reference
       *   - property.publication.additionalReferences
       *   - property.location.postalCode
       *   - property.location.locality
       *   - property.location.route
       *   - property.location.streetAddress
       * @example "50 rue, paris, refXXX"
       */
      q_fulltext?: string
      /** States of the mission. */
      states?: string // to add
      /** Type of the mission. */
      type?: FlatsyMissionType
      /**
       * ID of the Customer
       * @format uuid
       * @pattern ^[\w\-. ]+$
       */
      customer_id?: string
      sort?: string
      /** Whether or not the Mission is missing a valid Assignment. */
      staffing__missing_assignment?: boolean
      /** Whether or not the Mission has Bookings */
      has_bookings?: boolean
      /**
       * Current state of the third party system object (value will change based on third party)
       * @example "done"
       */
      third_party__state?: string
      /** The ID used in a third party system to represent the Mission (can be anything, any format, not necessarily uuid). */
      third_party__id?: string
      /** Subtype of the mission. */
      subtype?: (
        | 'COMMERCIAL_VIEWING_BUY'
        | 'COMMERCIAL_VIEWING_RENT'
        | 'INSPECTION_ENTRY'
        | 'INSPECTION_EXIT'
      )[]
      /** Whether or not an estimate of the costs of repairs of the property has been requested */
      is_repair_estimate_requested?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.request<
      {
        /**
         * The total of items found
         * @example 800
         */
        count?: number
        /**
         * The current page number
         * @example 4
         */
        page?: number
        /**
         * The number of items returned by page
         * @example 20
         */
        pageSize?: number
        /** List of useful links to navigate the collection */
        links?: {
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?page=3"
           */
          self?: string | null
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?page=4"
           */
          next?: string | null
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?page=2"
           */
          previous?: string | null
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?page=1"
           */
          first?: string | null
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?page=10"
           */
          last?: string | null
        }
      } & {
        items?: FlatsyMissionListItem[]
      },
      any
    >({
      path: `/admin/mission`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    })
  /**
   * @description Retrieve a <<glossary:Mission>> by ID
   *
   * @tags Mission
   * @name MissionDetail
   * @summary (Admin) Retrieve a Mission by ID
   * @request GET:/admin/mission/{id}
   * @secure
   */
  missionDetail = (id: string, params: RequestParams = {}) =>
    this.request<FlatsyProMissionInspection | FlatsyProMissionCommercialViewing, void>({
      path: `/admin/mission/${id}`,
      method: 'GET',
      secure: true,
      ...params,
    })
  /**
   * @description Update <<glossary:Mission>> data
   *
   * @tags Mission
   * @name MissionPartialUpdate
   * @summary (Admin) Update a Mission
   * @request PATCH:/admin/mission/{id}
   * @secure
   */
  // WARNING: keep data: any for patch
  missionPartialUpdate = (id: string, data: any, params: RequestParams = {}) =>
    this.request<FlatsyProMissionInspection | FlatsyProMissionCommercialViewing, void>({
      path: `/admin/mission/${id}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Update the state of a Mission
   *
   * @tags Mission
   * @name MissionStatePartialUpdate
   * @summary (Admin) Update the state of a Mission
   * @request PATCH:/admin/mission/{id}/state
   * @secure
   */
  missionStatePartialUpdate = (
    id: string,
    data: FlatsyPatchAdminMissionState,
    params: RequestParams = {}
  ) =>
    this.request<
      FlatsyProMissionInspection | FlatsyProMissionCommercialViewing,
      {
        errors?: (
          | 'AGENT_DOESNT_EXIST'
          | 'BOOKING_DOESNT_EXIST'
          | 'INVALID_INPUT_FOR_AGENT'
          | 'INVALID_INPUT_FOR_DURATION_IN_MINUTES'
          | 'INVALID_INPUT_FOR_REASON'
          | 'INVALID_INPUT_FOR_STATE'
          | 'INVALID_INITIAL_STATE'
          | 'MISSING_REASON'
          | 'MISSING_STATE'
        )[]
      } | void
    >({
      path: `/admin/mission/${id}/state`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description By updating the current bookings threshold, more bookings can be created for this mission
   *
   * @tags Mission
   * @name MissionBookingsCurrentThresholdPartialUpdate
   * @summary (Admin) Update the current threshold for Bookings
   * @request PATCH:/admin/mission/{id}/bookings_current_threshold
   * @secure
   */
  missionBookingsCurrentThresholdPartialUpdate = (
    id: string,
    data: FlatsyPatchMissionBookingsThreshold,
    params: RequestParams = {}
  ) =>
    this.request<
      FlatsyProMissionInspection | FlatsyProMissionCommercialViewing,
      {
        errors?: 'INVALID_THRESHOLD'[]
      } | void
    >({
      path: `/admin/mission/${id}/bookings_current_threshold`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Request Assignment creation
   *
   * @tags Assignment
   * @name MissionAssignmentCreate
   * @summary (Admin) Assign a FlatGuide for a Mission
   * @request POST:/admin/mission/{id}/assignment
   * @secure
   */
  missionAssignmentCreate = (id: string, data: FlatsyPostAssignment, params: RequestParams = {}) =>
    this.request<
      FlatsyAdminAssignment,
      | {
          errors?: string[]
        }
      | {
          errors?: 'ASSIGNMENT_ALREADY_EXISTS'[]
        }
    >({
      path: `/admin/mission/${id}/assignment`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Update the Assignment for a Mission
   *
   * @tags Assignment
   * @name MissionAssignmentPartialUpdate
   * @summary (Admin) Update the Assignment for a Mission
   * @request PATCH:/admin/mission/{id}/assignment
   * @secure
   */
  missionAssignmentPartialUpdate = (
    id: string,
    query: {
      /**
       * Start date of the Assignment
       * @format date
       */
      starts_at: string
      /**
       * ID of the Agent
       * @format uuid
       * @pattern ^[\w\-. ]+$
       */
      agent_id: string
    },
    data: FlatsyPatchAdminAssignment,
    params: RequestParams = {}
  ) =>
    this.request<
      FlatsyAdminAssignment,
      | {
          errors?: (
            | 'INVALID_INPUT_FOR_STATE'
            | 'INVALID_INPUT_FOR_REASON'
            | 'INVALID_INITIAL_STATE'
            | 'MISSING_REASON'
            | 'MISSING_STATE'
          )[]
        }
      | {
          errors?: 'ASSIGNMENT_DOESNT_EXIST'[]
        }
    >({
      path: `/admin/mission/${id}/assignment`,
      method: 'PATCH',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Get a list of mission templates from third party,currently restricted to INSPECTION type with Nockee as third party.
   *
   * @tags Mission
   * @name AdminGetThirdPartyTemplate
   * @summary (Admin) Get a list of mission templates supplied by third party
   * @request GET:/admin/third_party/template
   * @secure
   */
  adminGetThirdPartyTemplate = (
    query?: {
      /** Indicates the next items, uses to iterate instead of page */
      cursor?: string
      sort?: string[]
      page_size?: number
    },
    params: RequestParams = {}
  ) =>
    this.request<
      {
        /**
         * The total of items found
         * @example 800
         */
        count?: number
        /**
         * The cursor allowing to iterate
         * @example "cD0yMDI0LTA0LTA1KzFxJdNBMjAlM0E1Ny40MjkyNzQlMkIwMCDzQTAw"
         */
        cursor?: string
        /**
         * The number of items returned by page
         * @example 20
         */
        items?: FlatsyAdminThirdPartyTemplate[]
      },
      any
    >({
      path: `/admin/third_party/template`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    })
}
