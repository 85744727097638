import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    gap: 4,
    justifyContent: 'center',
  },
  time: {
    display: 'flex',
    justifyContent: 'right',
    width: 196,
  },
  icon: {
    color: theme.palette.secondary.dark,
  },
  description: {
    color: theme.palette.primary.dark,
    marginLeft: 8,
    fontWeight: 400,
  },
  divider: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    paddingRight: 4,
  },
  image: {
    objectFit: 'contain',
  },
  containerLogo: {
    display: 'flex',
    justifyContent: 'center',
  },
  containerButton: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerCard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    margin: 8,
  },
  containerSpinner: {
    flex: 1,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sidebar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  greyColor: {
    color: theme.palette.secondary.dark,
  },
  blueDarkColor: {
    color: theme.palette.primary.dark,
  },
  dateDurationLeft: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dateDurationRight: {
    display: 'flex',
    justifyContent: 'right',
  },
  loadingButton: {
    flex: 1,
  },
  fullContainer: {
    width: '100%',
  },
  pageContainer: {
    width: '100%',
  },
  fg: {
    display: 'flex',
    justifyContent: 'center',
  },
  topBar: {
    width: '100%',
    height: 'auto',
    background: `${theme.palette.common.white}!important`,
    backgroundColor: `${theme.palette.common.white}!important`,
    padding: '16px',
    border: 'none',
    boxShadow: 'none',
    display: 'flex',
    justify: 'space-between',
    alignItems: 'center',
  },
  topBarContent: {
    display: 'flex',
    justify: 'flex-end',
    alignItems: 'center',
  },
  logoWrapper: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    paddingRight: 16,
  },
  logo: {
    width: '100%',
    maxHeight: 40,
    objectFit: 'contain',
    objectPosition: 'left center',
  },
  logoContainer: {
    maxWidth: 200,
    height: 'auto',
  },
  accordion: {
    border: '1px solidr red',
    borderRadius: '8px!important',
    boxShadow:
      '0px 0px 0px 1px rgba(19, 45, 74, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)!important',
    '&.MuiAccordion-root:before': {
      height: 0,
    },
    marginBottom: 16,
  },
  slot: {
    padding: '6px 24px',
    background: theme.palette.primary.contrastText,
    marginBottom: 8,
    width: 100,
    margin: 0,
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 6,
    boxShadow:
      '0px 0px 0px 1px rgba(19, 45, 74, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)!important',
    '& .MuiTypography-root.MuiTypography-body1': {
      fontWeight: 500,
      color: theme.palette.common.black,
    },
  },
  iconMore: {
    color: theme.palette.secondary.dark,
  },
  boxInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 24px 0',
  },
  swipeable: {
    '& .MuiPaper-root.MuiPaper-elevation': {
      borderTopLeftRadius: '40px!important',
      borderTopRightRadius: '40px!important',
    },
  },
  lineGrey: {
    margin: '16px 0',
    width: 32,
    height: 4,
    background: theme.palette.secondary.main,
    borderRadius: 100,
  },
  containerLine: {
    display: 'flex',
    justifyContent: 'center',
  },
  iconCircle: {
    width: 20,
    height: 20,
    marginTop: 12,
    marginRight: 10,
    borderRadius: 8,
    background: theme.palette.common.white,
  },
  accordionDetail: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    paddingTop: '0px!important',
    justifyContent: 'center',
  },
  noBookable: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 0px 0px 1px rgba(19, 45, 74, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 32px',
    borderRadius: 8,
  },
  accordionSummary: {
    margin: '16px!important 0px',
    minHeight: '56px!important',
    '& .MuiAccordionSummary-content': {
      marginTop: '16px!important',
      marginBottom: '16px!important',
      margin: '0px!important',
    },
  },
  btnBook: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  contactUs: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    columnGap: 8,
  },
  phoneNumber: {
    whiteSpace: 'nowrap',
  },
}))

export default useStyles
