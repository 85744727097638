import {
  FlatsyMissionType,
  FlatsyPublicBookable,
} from '@flatsy/api/client/3.0.0/services/Mission/data-contracts'
import Separator from '@flatsy/core/Layout/Separator'
import LoaderSmall from '@flatsy/core/Loader/Small'
import { Body1, Header1 } from '@flatsy/core/MaterialUi/Typo'
import { toNameCase } from '@flatsy/core/MaterialUi/Typo/Utile'
import FlatsyTheme from '@flatsy/core/MuiTheme/flatsyTheme'
import theme from '@flatsy/core/MuiTheme/theme'
import { renderDuration } from '@flatsy/utils/Functions/Duration'
import { DateTime } from '@flatsy/utils/dates'
import { CircularProgress, useMediaQuery } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import EventIcon from '@mui/icons-material/Event'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import { Badge, Box, Button, List, ListItemText, SwipeableDrawer } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import clsx from 'clsx'
import { Fragment, useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'
import { Line } from 'src/components/Line'
import { LineDrawer } from 'src/components/LineDrawer'
import * as ROUTES from 'src/constants/Routes'
import { ApplicationContext } from 'src/context/Application'
import ContactSupport from '@flatsy/core/Icon/ContactSupport'
import { useBookableByMissionId } from 'src/hooks/Booking'
import { useAppDispatch } from 'src/redux'
import { AlertSlice } from 'src/redux/slices/Alert'
import useStyles from './Style'
import { BookableProps } from './Type'

export const Bookable: React.FC<BookableProps> = ({ missionId, startsAt, endsAt }) => {
  const { i18n } = useTranslation()
  const classes = useStyles()
  const {
    missionQuery,
    token,
    booking: { createBooking, selected, setSelected },
  } = useContext(ApplicationContext)
  const [isBooking, setIsBooking] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<string | false>(false)
  const { bookable } = useBookableByMissionId(token, missionId, startsAt, endsAt)
  const [transitionLoading, setTransitionLoading] = useState(true)
  const [showMoreSlot, setShowMoreSlot] = useState<boolean>(false)
  const screenXs = useMediaQuery(theme.breakpoints.down('xs'))
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [nbSlot, setNbSlot] = useState({
    date: '',
    nb: 0,
  })
  const [state, setState] = useState({
    bottom: false,
  })

  useEffect(() => {
    setTimeout(() => {
      setTransitionLoading(false)
    }, 1500)
  }, [])

  if (
    transitionLoading ||
    (bookable && bookable.isLoading === true) ||
    !bookable ||
    !bookable.data ||
    bookable.data === undefined ||
    bookable.status === 'error'
  ) {
    return (
      <>
        <Separator size="16px" />
        <LoaderSmall />
      </>
    )
  }

  const groupedDateBookable = () => {
    const groupedData: { [date: string]: any[] } = {}
    if (bookable && bookable.data && bookable.data.length > 0) {
      bookable.data.forEach((item) => {
        const dateKey = item.startsAt.split('T')[0]
        if (!groupedData[dateKey]) {
          groupedData[dateKey] = []
        }
        groupedData[dateKey].push(item)
      })
    }
    return groupedData
  }

  const toggleDrawer = (open: boolean, value?: string, closeSelected?: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    setSelected(value)
    if (closeSelected) {
      setSelected('')
    }
    setState({ ...state, bottom: open })
  }

  const handleSubmit = () => {
    if (
      createBooking &&
      !isBooking &&
      missionQuery?.data?.type === FlatsyMissionType.INSPECTION &&
      selected
    ) {
      setIsBooking(true)
      createBooking(selected)
        .then(() => {
          setSelected(selected)
          history.push(
            generatePath(ROUTES.APPLICATION_SUCCESS, {
              public_token: token,
            })
          )
        })
        .catch((err) => {
          dispatch(
            AlertSlice.actions.setAlert({
              content: toNameCase(i18n.t(`${err.errors[0].toLocaleLowerCase()}`)),
              icon: <CancelIcon />,
              open: true,
              severity: 'error',
              timing: 3000,
            })
          )
          setIsBooking(false)
        })
    }
  }

  const drawerSelected = () => (
    <Box role="presentation">
      <LineDrawer />
      <div className={classes.boxInfo}>
        <div className={classes.dateDurationLeft}>
          <div className={classes.flex}>
            <EventIcon />
            <Body1>
              {selected && i18n.t('@date_abbreviated', { value: DateTime.fromISO(selected) })}
            </Body1>
          </div>
        </div>
        <div className={classes.dateDurationRight}>
          <div className={clsx(classes.flex, classes.time)}>
            <TimerOutlinedIcon />
            <Body1>{missionQuery && missionQuery.data && renderDuration(missionQuery.data)}</Body1>
          </div>
        </div>
      </div>
      <Separator size="16px" />
      <Divider />
      <Separator size="8px" />
      <List>
        <ListItem key={1} disablePadding>
          <ListItemButton style={{ backgroundColor: theme.palette.primary.light }}>
            {!isBooking ? (
              <ListItemText
                onClick={handleSubmit}
                primary={<Body1 className={classes.btnBook}>{i18n.t('book_my_appointment')}</Body1>}
                style={{ textAlign: 'center' }}
              />
            ) : (
              <div className={classes.containerSpinner}>
                <CircularProgress size={24} />
              </div>
            )}
          </ListItemButton>
        </ListItem>
        <ListItem key={2} disablePadding>
          <ListItemButton disabled={isBooking} onClick={toggleDrawer(false, selected, true)}>
            <ListItemText
              primary={i18n.t('select_a_different_time')}
              style={{ textAlign: 'center' }}
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Separator size="32px" />
    </Box>
  )

  const handleChange = (items: any[], date: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    event.preventDefault()
    // button show more
    if (nbSlot.date !== date || !nbSlot.date) {
      setNbSlot({ date, nb: items.length })
      setShowMoreSlot(false)
    }
    setExpanded(isExpanded ? date : false)
  }

  const renderSelected = (startsAt: string, index: number) => {
    if (screenXs && nbSlot.nb >= 12 && index >= 12 && showMoreSlot === false) {
      return
    }
    if (selected === startsAt) {
      return (
        <Badge
          key={startsAt}
          invisible={!selected}
          badgeContent={
            <CheckCircleIcon
              className={classes.iconCircle}
              style={{
                color: theme.palette.primary.dark,
              }}
            />
          }
        >
          <Button
            className={classes.slot}
            onClick={toggleDrawer(true, startsAt)}
            style={{ backgroundColor: theme.palette.primary.light }}
          >
            <Body1>{i18n.t('@date_time', { value: DateTime.fromISO(startsAt) })}</Body1>
          </Button>
        </Badge>
      )
    }
    return (
      <Button className={classes.slot} style={{}} onClick={toggleDrawer(true, startsAt)}>
        <Body1>{i18n.t('@date_time', { value: DateTime.fromISO(startsAt) })}</Body1>
      </Button>
    )
  }

  const handleShowMoreSlot = () => {
    setShowMoreSlot(true)
  }

  const renderContactUs = () => {
    return (
      <div className={classes.contactUs}>
        <ContactSupport />
        <Body1>
          <Trans
            i18nKey={!bookable.data.length ? 'contact_us_at' : 'contact_us_at_if_you_need_help'}
            components={[<span className={classes.phoneNumber} />]}
          />
        </Body1>
      </div>
    )
  }

  const renderNoBookable = () => {
    return (
      <div className={classes.noBookable}>
        <img src={FlatsyTheme.image.noBooking} alt="no bookable slot" style={{ width: 272 }} />
        <Separator size="32px" />
        <Header1 lowerCase>{i18n.t('no_time_slot_available')}</Header1>
        <Separator size="16px" />
        {renderContactUs()}
        <Separator size="40px" />
      </div>
    )
  }

  const renderShowMoreTime = () => {
    if (!showMoreSlot && screenXs) {
      return (
        <>
          <Separator size="8px" />
          <Line />
          <Separator size="0.1px" />
          {nbSlot.nb >= 12 && !showMoreSlot && (
            <div style={{ textAlign: 'center', width: '100%' }}>
              <Button
                style={{
                  textTransform: 'none',
                  color: theme.palette.primary.dark,
                  padding: 0,
                }}
                onClick={handleShowMoreSlot}
              >
                {toNameCase(i18n.t('show_more_time_slots'))}
              </Button>
            </div>
          )}
        </>
      )
    }
  }

  return bookable.data.length === 0 ? (
    renderNoBookable()
  ) : (
    <Fragment key="bottom">
      {Object.entries(groupedDateBookable()).map(([date, items]) => (
        <Accordion
          expanded={expanded === date}
          onChange={handleChange(items, date)}
          className={classes.accordion}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${date}_bookable_mobile-content`}
            id={`${date}_bookable_mobile-header`}
            className={classes.accordionSummary}
          >
            <Body1>{toNameCase(i18n.t('@date_full', { value: DateTime.fromISO(date) }))}</Body1>
          </AccordionSummary>
          <div>
            <AccordionDetails className={classes.accordionDetail}>
              <Line />
              <Separator size="4px" />
              {items.map((item: FlatsyPublicBookable, index: number) =>
                renderSelected(item.startsAt, index)
              )}
              {renderShowMoreTime()}
            </AccordionDetails>
          </div>
          <Separator size="4px" />
        </Accordion>
      ))}

      {bookable.data.length < 4 && renderContactUs()}

      <SwipeableDrawer
        anchor="bottom"
        open={state.bottom}
        onClose={toggleDrawer(false, selected)}
        onOpen={toggleDrawer(true, selected)}
        className={classes.swipeable}
      >
        {drawerSelected()}
      </SwipeableDrawer>
    </Fragment>
  )
}
